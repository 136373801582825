export const WETH_ADDRESS = '0x74B033e56434845E02c9bc4F0caC75438033b00D';
export const WBTC_ADDRESS = '0xfB83c18569fB43f1ABCbae09Baf7090bFFc8CBBD';

export const TOKEN_OPTIONS = [
  {
    value: WETH_ADDRESS,
    label: 'WETH',
  },
  {
    value: WBTC_ADDRESS,
    label: 'WBTC',
  },
];
